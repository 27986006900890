import VideoDisplayComponent from './VideoDisplayComponent';

function App() {
  return (
    <div className="App">
      <VideoDisplayComponent />
    </div>
  );
}

export default App;

