import React, { useState } from 'react';
import './VideoDisplayComponent.css';

const Button = ({ children, className = '', ...props }) => (
  <button className={`button ${className}`} {...props}>
    {children}
  </button>
);

const Input = ({ className = '', ...props }) => (
  <input className={`input ${className}`} {...props} />
);

const Alert = ({ children, type = 'info' }) => (
  <div className={`alert ${type}`} role="alert">
    {children}
  </div>
);

const Loader = () => <div className="spinner"></div>;

const Card = ({ children, className = '' }) => (
  <div className={`card ${className}`}>
    {children}
  </div>
);

const CardContent = ({ children }) => (
  <div className="card-content">
    {children}
  </div>
);

const VideoDisplayComponent = () => {
  const [videoEntries, setVideoEntries] = useState([]);
  const [newEntries, setNewEntries] = useState([{ file: null, id: '' }]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [apiResponse, setApiResponse] = useState([]);


  const handleFileChange = (index, file) => {
    const updatedEntries = [...newEntries];
    updatedEntries[index].file = file;
    setNewEntries(updatedEntries);
  };

  const handleIdChange = (index, id) => {
    const updatedEntries = [...newEntries];
    updatedEntries[index].id = id;
    setNewEntries(updatedEntries);
  };

  const handleAddEntry = () => {
    setNewEntries([...newEntries, { file: null, id: '' }]);
  };

  const handleRemoveNewEntry = (index) => {
    const updatedEntries = newEntries.filter((_, i) => i !== index);
    setNewEntries(updatedEntries.length ? updatedEntries : [{ file: null, id: '' }]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Filter valid entries that have both 'file' and 'id'
    const validEntries = newEntries.filter(entry => entry.file && entry.id);


    if (validEntries.length > 0) {
      setLoading(true);
      try {
          const formData = new FormData();

          // 1. Create a comma-separated string of all IDs
          const lectureIds = validEntries.map(entry => entry.id).join(',');

          // 2. Gather all video files into a list
          const filesList = validEntries.map(entry => entry.file);

          // 3. Append the string of IDs to the form data
          formData.append('lectureIds', lectureIds);

          // 4. Append each file in the filesList to the form data
          filesList.forEach((file, index) => {
            formData.append('files', file);
          });

          // 5. Make the API call, passing the FormData object
          const response = await fetch(`https://api-video-upload-dev.visionias.in/upload/${lectureIds}`, {
//          const response = await fetch(`http://localhost:8004/upload/${lectureIds}`, {
            method: 'POST',
            body: formData,
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        const result = await response.json();
//        setVideoEntries([...videoEntries, ...validEntries]);
        setNewEntries([{ file: null, id: '' }]); // Create a new empty object
        document.querySelectorAll('input[type="file"]').forEach(input => {
          input.value = null;  // Clear file input field
        });
         setApiResponse(result.result);
         setAlert({ message: result.message, type: 'success' });
      } catch (error) {
        setAlert({ message: `Upload failed: ${error.message}`, type: 'error' });
      } finally {
        setLoading(false); // Stop loader after the response
      }
    }
  };

  const handleRemoveEntry = (index) => {
    setVideoEntries(videoEntries.filter((_, i) => i !== index));
  };

  return (
    <div className="video-display-container">
      <h2 className="title">Video Display</h2>
      
      {apiResponse.length > 0 && (
  <div className="api-response">
    <h3>Upload Results</h3>
    <ul>
      {apiResponse.map(({ lecture_id, status }, index) => (
        <li key={index} className={status ? 'success' : 'error'}>
          Lecture ID: {lecture_id} - Status: {status ? 'Success' : 'Failed'}
        </li>
      ))}
    </ul>
  </div>
)}

      {loading && <Loader />} {/* Display loader when loading */}

      <div className="video-entries">
        {videoEntries.map(({ file, id }, index) => (
          <div key={index} className="video-entry">
            <Card className="flex-grow">
              <CardContent>
                <div className="entry-content">
                  <span className="file-name" title={file.name}>{file.name}</span>
                  <span className="file-id" title={id}>{id}</span>
                </div>
              </CardContent>
            </Card>
            <Button 
              onClick={() => handleRemoveEntry(index)}
              className="remove-button"
            >
              🗑️
            </Button>
          </div>
        ))}
      </div>
      
      <form onSubmit={handleSubmit} className="new-entries-form">
        {newEntries.map((entry, index) => (
          <Card key={index}>
            <CardContent>
              <div className="new-entry">
                <Input 
                  type="file" 
                  accept="video/*"
                  onChange={(e) => handleFileChange(index, e.target.files[0])}
                  className="file-input"
                  required
                />
                <Input 
                  type="text" 
                  placeholder="Video ID" 
                  value={entry.id}
                  onChange={(e) => handleIdChange(index, e.target.value)}
                  className="id-input"
                  required
                />
                <Button 
                  type="button"
                  onClick={() => handleRemoveNewEntry(index)}
                  className="remove-button"
                >
                  🗑️
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
        <div className="form-actions">
          <Button type="button" onClick={handleAddEntry} className="add-button" disabled={loading}>
            ➕ Add Another
          </Button>
          <Button type="submit" className="submit-button" disabled={loading}>
            {loading ? 'Uploading...' : 'Submit All'} {/* Change button text when loading */}
          </Button>  
        </div>
      </form>
      
      {videoEntries.length === 0 && newEntries.length === 1 && !newEntries[0].file && (
        <Alert>
          <p>No videos added yet. Use the form above to add videos.</p>
        </Alert>
      )}
    </div>
  );
};

export default VideoDisplayComponent;